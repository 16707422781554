html {
  height: 100%;
}

body {
  background-size: cover;
  font-family: "Montserrat";
  letter-spacing: 0.3px;
  min-height: 100%;
  background-attachment: fixed;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px transparent;
}

textarea {
  resize: none;
  overflow: hidden;
  /* min-height: 50px; */
  /* max-height: 100px; */
}

.inputText {
  padding-right: 5px;
  border-right: 1px solid rgb(173, 173, 173);
  display: inline-flex;
}

.breadCrumb {
  position: relative;
  top: 8px;
  z-index: 1;
  padding: 0px 0px 0px 12px;
  color: gray;
  font-size: 11px;
}
.noteData{
  z-index: 1;
  padding: 6px 0px 0px 12px;
  color: gray;
  font-size: 11px;
}
.notesList{
  /* max-height: 230px;
  overflow-y: scroll; */
}
.notesItem{
  background-color: #ecf3f9;
  min-height: 56px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 1px 2px 2px rgba(20, 48, 82, 0.12);
  margin-right: 2px;
}

.notesItemUnread{
  background-color: #ffebe4;
  min-height: 56px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 1px 2px 2px rgba(20, 48, 82, 0.12);
  margin-right: 2px;
}

/*Generic classes Start*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pos_rel {
  position: relative;
}

.pt-50 {
  padding-top: 50px;
}

.font-weig-4 {
  font-weight: 400 !important;
}

.mt-20 {
  margin-top: 20px;
}

/*Generic classes End*/
.modal-header,
.modal-body {
  padding: 10px !important;
}

.medications_inner h5:empty {
  margin: 0px !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.container {
  max-width: 90%;
}

.containerCustom {
  max-width: 90%;
}

.login_section {
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.login_section_inner {
  padding: 20px 110px;
  background: rgba(250, 253, 255, 0.8);
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-radius: 8px;
  border: 0;
}

.logo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.logo img {
  width: 150px;
}

.login_section p {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
}

.insurance_inner h1,
.medications_inner_coupon h1 {
  font-weight: 600;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
}

.medication-heading h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 38px;
}

.login_section h3 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
  line-height: 26px;
  margin: 0 0 20px 0;
}

.offer-section {
  margin: 12px 0px 0px 0px;
  padding: 14px;
  border: 2px solid #00000026;
  border-radius: 10px;
  font-size: 12px;
  line-height: 14px;
}

.offer-section span {
  margin-left: 30px;
}

.offer-section i {
  color: #1C3469;
  /* font-size: 21px !important; */
}

.offer-section a {
  color: #1C3469;
  font-weight: bold;
}


.page_counter h3 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin: 0;
  color: rgba(255, 255, 255, 1);
  line-height: 26px;
}

.medications_inner h3 {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
  line-height: 26px;
}


.tooltip-inner {
  background-color: white;
  color: #323232;
  border: 1px solid #bbb3b3;
  text-align: left;
  max-width: 173px;
  font-size: 13px;
}

.best-cheap-offer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  /* cursor: pointer; */
}


/* drawer styles start*/
.offcanvas {
  --bs-offcanvas-width: 320px !important;
}

.offcanvas-body {
  padding: 0px 10px;
}

.right-aligned-item {
  display: flex;
  justify-content: flex-end;
}

.bold-text {
  font-size: 24px;
  font-weight: bold;
}

.drawer-item {
  /* border: 1px solid gray; */
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  padding: 10px 0px 10px 10px;
  border-radius: 8px;
  margin: 12px 0px 0px 0px;
}

.drawer-item:hover {
  background-color: #ebebeb;
  cursor: pointer;
}

.saving-item {
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  padding: 10px 0px 10px 10px;
  border-radius: 8px;
  margin: 12px 0px 0px 0px;
}


.icon-forward {
  align-self: center;
  padding-left: 18px;
}

.logout-item {
  position: fixed;
  bottom: 10px;
  width: 300px;
}

/* drawer styles end */
/* find pharmacy styles start*/
.map-back-icon {
  position: relative;
  z-index: 100;
  left: 80px;
  top: 20px;
}

.webView-search-container {
  position: absolute;
  width: 560px;
  max-height: 85%;
  right: 8vw;
  top: 104px;
  z-index: 100;
  background-color: white;
  padding: 30px 24px 18px 20px;
  border-radius: 12px;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: 10px 10px 20px 6px rgba(20, 48, 82, 0.12);
  /* opacity: 0.95; */
}

.pac-container:after {
  content: none !important;
}

.pac-container {
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 220px;
  overflow-y: auto;
}

/* Custom styles for each dropdown item */
.pac-item {
  padding: 18px 12px;
  cursor: pointer;
  border-top: 0px;
}

.pac-icon {
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 0px 0px;
  width: 0px;
  height: 0px;
}

/* Custom styles for the selected/drop-down highlighted item */
.pac-item-selected,
.pac-item:hover {
  background-color: #f1f1f1;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.tabView-search-container {
  position: absolute;
  width: 77vw;
  max-height: 560px;
  left: 77px;
  top: 104px;
  z-index: 100;
  background-color: white;
  padding: 20px 35px;
  border-radius: 12px;
  /* overflow-y: auto; */
}

.tabView-search-container h1 {
  color: #4b4c4d;
  font-size: 27px;
  line-height: 37px;
}

.tabView-search-container h6 {
  color: #4b4c4d;
  margin: 10px 0px;
  font-size: 13px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.2px;
}

.webView-search-container h1 {
  color: #4b4c4d;
  font-size: 27px;
  line-height: 37px;
}

.webView-search-container h6 {
  color: #4b4c4d;
  margin: 10px 0px;
  font-size: 13px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.2px;
}

.icon-position-input i {
  position: relative !important;
  top: 41px;
  left: 10px;
}

.section-divider {
  border-top: 1px solid #00000026;
  margin: 12px 0px;
}

.section-divider-vertical {
  border-left: 1px solid #00000026;
}

.slider-button {
  border-top: 2px solid rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);

  /* margin: 12px 2px 12px 2px; */
  width: 20px;
  /* margin-left: 50%; */
  /* margin: 2px; */
  /* margin-top: -3px; */
  cursor: pointer;
}

.slider-contianer {
  border-radius: 12px;
  /* width: 20px; */
  display: flex;
  justify-content: center;
}

.pharma_inner-extended {
  margin: 8px 26px 0px 26px !important;
  align-items: flex-start;
  padding: 5px 12px !important;
  gap: 16px;
  background: #fafdff;
  box-shadow: 0px 4px 4px rgba(20, 48, 82, 0.12) !important;
  border-radius: 8px;
  /* margin: 25px 25px 0px 25px; */
}

.pharma-listing {
  overflow-y: auto;
  max-height: 45vh;
  background-color: white;
  padding: 5px;
}

.tab-pharma-listing {
  position: absolute;
  z-index: 107;
  background-color: transparent;
  bottom: 56px;
  width: 100%;
  padding: 10px 0px;
  /* top: 44%; */
  display: block;
  max-height: 40%;
  overflow: scroll;
}

.gmnoprint[aria-label="Street View"] {
  display: none !important;
}

.mobile-input {
  position: absolute;
  z-index: 2;
  background-color: white;
  width: 100%;
}

.autocomplete-dropdown-container {
  position: sticky;
}

.xMarkIcon {
  float: right;
  margin-right: 20px;
  cursor: pointer;
  color: gray;
}

.xMarkIconMobile {
  position: absolute;
  cursor: pointer;
  color: gray;
  right: 32px;
  top: 18px;
}

.no-record {
  position: fixed;
  /* width: 33.5%; */
  background: rgba(250, 253, 255, 1);
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
}

/* find pharmacy styles end*/

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.card_info_new h3,
.card_info_new p {
  color: #fff !important;
  text-transform: capitalize;
}

.page_counter h4 {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin: 0;
  color: rgba(255, 255, 255, 1);
  line-height: 22px;
}

.medications_inner h4 {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
  line-height: 22px;
}

.medications_detail h4 {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 22px;
}

.coupon_link a {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 22px;
}

.medications_inner h5,
.card_info_new h5 {
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.2px !important;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 5px;
}

.flex-end {
  align-self: flex-end;
  padding-bottom: 3px;
  padding-left: 5px;
}

.card_info_new h5 {
  color: #fff !important;
}

.insurance_inner p {
  margin: 10px 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.7);
}

.medications_inner p {
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.2px;
  margin: 0;
}

.login_section .password a {
  font-weight: 600;
  font-size: 13px !important;
  text-align: center;
  color: #1c3469 !important;
  line-height: 19px;
}

.medications_inner a {
  color: rgba(28, 52, 105, 1);
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  line-height: 19px;
}

.medications_inner_coupon label {
  color: rgba(0, 0, 0, 0.7);
  font-size: 11px;
  font-weight: 600 !important;
  margin: 0;
  line-height: 14px;
  letter-spacing: 0.8px;
}

.button_div {
  display: flex;
}

.margin-right {
  margin-right: 10px;
}

.dlr-Sup sup {
  /* font-size: 11px; */
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.3px;
}

.dlr-Sup-snd sup {
  color: #000;
  font-weight: 600;
}

.middle {
  position: absolute;
  vertical-align: middle;
  text-align: center;
  padding: 1em 0 1em 0;
}

.peiceUpdate {
  padding: 12px !important;
}

.insurance-padding {
  padding: 20px !important;
}

.custom-form-control {
  font-size: 13px !important;
  padding: 11px 12px !important;
  border-radius: 8px !important;
  line-height: 19px;
  letter-spacing: 0.2px !important;
  border: 0 !important;
}

.input-with-label {
  position: absolute;
  top: 3px;
  left: 8px;
  background-color: none;
  font-size: 10px;
}

.custom-form-select {
  margin: 3px 0;
  background-color: rgba(236, 243, 249, 1) !important;
  height: 42px !important;
  border-radius: 8px;
}

.form-select-is-invalid {
  background: rgba(236, 243, 249, 1) !important;
  border: 1px solid rgba(221, 91, 117, 1) !important;
}

.date-picker-container,
.date-picker-container>div.react-datepicker-wrapper,
.date-picker-container>div>div.react-datepicker__input-container .date-picker-container>div>div.react-datepicker__input-container input {
  width: 100%;
}


.date-picker-feedback {
  display: block;
  margin-top: -1px;
}

.fa-pencil {
  position: inherit !important;
  padding-right: 5px;
}

.edit-Icon {
  padding-left: 2px;
}

.NIbutton {
  width: auto !important;
}

.displayNone {
  display: none;
}

.del-done {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1c3469;
}

.header_back {
  background-color: #223555;
  border-radius: 8px;
  margin: 10px 0;
  padding: 7px 0;
}

.header_back img {
  height: 35px;
}

.top_heading {
  text-align: center;
  margin: 0 auto;
  padding: 15px 0;
}

.top_heading h1 {
  color: #fff;
}

.login_btn {
  width: 100%;
  background-color: #4adeb9 !important;
  font-weight: bold !important;
  border: 0 !important;
  padding: 15px 0 !important;
  font-size: 18px !important;
  text-transform: uppercase;
}

.login_box {
  max-width: 400px;
  margin: 0 auto;
}

.login_box a {
  color: #000;
}

.login_box a:hover {
  color: #78aba2;
}

.login_box i {
  margin-right: 5px;
  color: #295e86;
}

.login_box p {
  color: #000;
  font-weight: 600;
}

.login_input {
  border: 0 !important;
}

.checkbox_login {
  font-size: 14px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.forget_section {
  margin: 0 auto;
  text-align: center;
}

.forget_section .row {
  margin: 10px 0;
  padding: 5px 0;
}

.forget_box {
  max-width: 400px;
  margin: 0 auto;
}

.forget_box p {
  color: #416e35;
  font-weight: 600;
}

.forget_box i {
  margin-right: 5px;
  color: #77aca2;
}

.forget_input {
  border: 1px solid #79aba2 !important;
  height: 50px;
  border: 0 !important;
}

.forget_input_eye {
  position: relative;
}

.forget_input_eye i {
  position: absolute;
  top: 10px;
  right: 8px;
}

.error-circle {
  position: absolute;
  right: -20px;
  top: 10px;
}

.error-circle-dob {
  position: absolute;
  right: -2.5%;
  top: 10px;
}

.error-circle-dob-mobile {
  position: absolute;
  right: -7%;
  top: 10px;
}

.error-circle-dob-login {
  position: absolute;
  right: -26px;
  top: 10px;
}

.error-circle-account {
  position: absolute;
  right: 1.5%;
  top: 10px;
}

.error-circle-account-mobile {
  position: absolute;
  right: -3.5%;
  top: 10px;
}

.error-circle-insurance {
  position: absolute;
  right: 1px;
  top: 10px;
}

.error-circle-acount {
  position: absolute;
  right: 2.5%;
  top: 10px;
}

.error-circle-sms {
  position: absolute;
  right: -6px;
  top: 10px;
}

/* .error-circle-sms{
  position: absolute;
  right: -16px;
  top: 14px;
} */
.error-circle-checkbox {
  position: absolute;
  right: 16px;
  top: 0px;
}

.forget_btn {
  width: 100%;
  background-color: #2b5f86 !important;
  font-weight: bold !important;
  border: 0 !important;
  padding: 15px 0 !important;
  font-size: 18px !important;
  text-transform: uppercase;
}

.center {
  margin: auto;
  width: 40%;
  padding: 10px;
  text-align: center;
}

.commingSoonBtn {
  width: 50%;
  background-color: #2b5f86 !important;
  font-weight: bold !important;
  border: 0 !important;
  padding: 10px 0 !important;
  font-size: 14px !important;
  text-transform: uppercase;
}

footer {
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  background-color: #367c9f !important;
  color: #fff;
  padding: 5px 0;
  border-top: 1px solid #fff;
}

footer p {
  margin-bottom: 0 !important;
  font-size: 14px;
}

.add_insurance_card {
  background-color: #2b5f86;
  border: 0;
  border-radius: 7px;
  padding: 10px 10px;
  margin: 10px 0;
  position: relative;
  min-height: 100px;
}

.add_insurance_card h3 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.drug_inner {
  margin: 10px 0;
  padding: 10px 0;
  background-color: inherit;
}

.drug_inner .row {
  margin-bottom: 10px;
}

.drug_inner label {
  padding-bottom: 5px;
  font-size: 14px;
}

.add_medication_input {
  border-radius: 25px !important;
  border: 1px solid #79aca3 !important;
}

.drug_inner button {
  margin-top: 30px;
  background-color: #416e35;
  border-radius: 25px;
  border: 0 none;
  width: 86px;
  font-weight: 600;
}

.drug_inner button:hover {
  background-color: #416e35;
}

.next_btn_medication {
  background-color: #79aaa4 !important;
}

.medFlex {
  display: inline-flex;
}

.med-Flex {
  display: flex;
}

.insurance_inner {
  padding: 20px;
  gap: 16px;
  background: #fafdff;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-radius: 8px;
  margin: 15px 0;
  text-align: left;
  border: 0;
}

.insurance_inner h1,
.insurance_inner h6 {
  color: #4b4c4d !important;
}

.insurance_inner input {
  margin: 2px 0;
}

.insurance_inner span {
  color: #000 !important;
  font-weight: 600;
  font-size: 15px !important;
}

.insurance_inner h6 {
  margin: 10px 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.2px;
}

.insurance_inner h3 {
  text-align: center;
}

.insurance_inner h3>span {
  font-size: 22px !important;
  color: #000 !important;
}

.insurance_inner button {
  text-align: center;
  margin: 0 auto;
}

.fa-user {
  margin-left: 10px;
}

.medications_inner {
  margin: 6px 0px;
  padding: 16px 16px 16px 16px;
  gap: 16px;
  background: #fafdff;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-radius: 8px;
  text-align: left;
  border: 0;
}

.medications_inner_imhere {
  background: #fafdff;
}

.tabCoupon .medications_inner_imhere,
.tabCoupon .medications_inner {
  box-shadow: none !important;
  background: #fff !important;
}

.coupon p,
.font16 {
  font-size: 16px !important;
}

.coupon-shopName {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #4b4c4c !important;
}

.medications_inner_right {
  text-align: right;
}

.medications_inner_right span {
  color: #4b4c4d;
}

.clr_a2a4a5 {
  color: rgba(0, 0, 0, 0.35) !important;
  font-weight: normal !important;
}

.normal {
  font-weight: normal !important;
}

.summary {
  display: block !important;
}

.tab-review-coupon {
  margin: 15px 0px !important;
  padding: 15px 5px !important;
}

.review-coupon {
  margin: 10px 0px;
  padding: 10px 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.btn-wallet {
  background-color: green !important;
  border: 0 !important;
  border-radius: 25px !important;
  margin: 15px auto;
  text-align: center !important;
  color: #fff !important;
  padding: 15px 30px !important;
  background: rgb(121, 171, 163);
  background: linear-gradient(180deg,
      rgba(121, 171, 163, 1) 30%,
      rgba(65, 110, 53, 1) 90%);
  font-weight: bold !important;
  text-transform: uppercase !important;
}

.header_back nav {
  width: fit-content;
}

.header_back ul {
  width: max-content;
}

.header_back h4 {
  padding-top: 12px;
  color: #fff;
  font-weight: bold;
  margin-left: 10px;
}

.header_back a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.header_back a:hover {
  color: #fff;
  cursor: pointer;
}

.navbar-dark {
  margin: 0 0 0 auto;
}

.med_inne_better_offer_div {
  border-radius: 25px !important;
  margin: 10px 0 !important;
  padding: 10px 0 !important;
  text-align: center !important;
  background-color: #426f34 !important;
  color: #fff !important;
  border: 0 !important;
  width: 100% !important;
  font-weight: bold !important;
  font-size: 19px !important;
}

.med_inne_better_offer_div:hover {
  background-color: #143e13 !important;
  color: #fff !important;
  font-weight: bold !important;
}

.drugs_inner_best_deals {
  background-color: #fafdff;
  border: 0;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 10px 0;
  box-shadow: -2px 4px 15px -3px #888;
}

.drugs_inner_best_deals h4 {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}

.drugs_inner_best_deals label {
  padding-bottom: 5px;
  margin: 20px 0;
}

.drugs_inner_best_deals input {
  border: 1px solid #77aaa5;
  border-radius: 15px;
  margin: 20px 0;
}

.drugs_inner_best_deals p {
  color: #4aa0dd;
  padding-top: 0px;
  font-size: 12px;
  margin-bottom: 10px;
}

.drugs_inner_best_deals .dropdown {
  margin: 15px 0;
}

.home_one {
  gap: 16px;
  margin: 25px;
}

.pharma_home_one {
  margin: 25px;
}

.home_one a {
  text-decoration: none;
}

.home_one img {
  max-width: 328px;
  min-width: auto;
}

.border-top {
  border-top: 1px solid rgb(204, 204, 204);
}

.price_comparison_home_4 h6 {
  margin-top: 17px;
  font-size: 18px;
  color: #000;
  display: inline-block;
}

.flex {
  display: flex;
  justify-content: center;
}

.justify-content {
  justify-content: start !important;
}

.btn:active {
  border: transparent !important;
}

.price_comparison_home_4 p {
  margin-bottom: 0;
  font-weight: bold;
  margin-top: 2px;
  font-size: 20px;
  color: #000;
}

.price_comparison_home_4 span {
  color: #000;
  text-align: center;
  font-size: 12px;
}

.price_comparison_home_4 a {
  color: #000;
}

.price_comparison_home_4 a:hover {
  color: #000;
}

.insurance_inner_coupon_card {
  margin: 10px 0;
  padding: 10px 0;
  background-color: inherit;
  text-align: left;
  background-color: #6094bb;
  border: 0;
  box-shadow: -2px 4px 15px -3px #888;
  padding: 13px;
  border-radius: 8px;
}

.insurance_inner_coupon_card input {
  margin: 10px 0;
}

.insurance_inner_coupon_card span {
  color: #1f5f8d !important;
  font-weight: 600;
  font-size: 15px !important;
}

.insurance_inner_coupon_card p {
  font-size: 19px !important;
  color: #fff !important;
}

.insurance_inner_coupon_card h3 {
  text-align: center;
  font-size: 30.5px !important;
}

.insurance_inner_coupon_card h3>span {
  font-size: 22px !important;
  color: #000 !important;
}

.insurance_inner_coupon_card button {
  text-align: center;
  margin: 0 auto;
}

.insurance_inner_coupon_card img {
  width: 100px;
  margin-bottom: 15px;
}

.print_to_phone {
  margin-top: 25px;
  text-align: center;
}

.print_to_phone p {
  margin-bottom: 0;
}

.print_to_phone button {
  background-color: #2b5f86;
  color: #fff;
  margin: 30px 0;
  padding: 5px 28px;
  border-radius: 27px;
}

.print_to_phone button:hover {
  background-color: #2b5f86;
  color: #fff;
}

.print_to_phone img {
  max-width: 100px;
  height: auto;
  margin-top: 29px;
}

.medicartion_search_btn {
  background-color: #a8dadc !important;
  color: #000 !important;
  border: 0 none !important;
}

.add_insurance_new h3 {
  margin-bottom: 20px;
}

.add_insurance_new span {
  font-size: 18px;
  margin-right: 25px;
}

.add_insurance_new input {
  margin-right: 3px;
}

.add_insurance_new label {
  margin-right: 7px;
}

.coupon_heading {
  font-size: 19px;
}

.medicartion_search_btn {
  background-color: #fff !important;
  border-radius: 20px !important;
  margin-top: 20px !important;
  text-align: left !important;
}

.medicartion_search_btn::after {
  content: none !important;
}

.medicartion_search_btn i {
  float: right;
  margin-top: 5px;
}

.add_medication_new_inner {
  border: none;
  background-color: #fff;
  padding: 16px 20px;
  border-radius: 14px;
  margin: 0 0 16px 0;
}

.add_medication_new_inner a {
  cursor: pointer;
}

.add_medication_new_inner p {
  display: inline-block;
  margin-bottom: 0;
  color: #000;
}

.add_medication_new_inner i {
  float: right;
  color: #000;
  padding-top: 4px;
}

.coupon_new_div_saving {
  padding: 20px 5px;
}

.coupon_new_div_saving h6 {
  font-size: 19px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 14px;
  color: #fff;
}

.apple_btn img {
  max-width: 100%;
  height: 38px;
}

.coupon_new_div_saving_inner {
  padding: 5px 8px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
}

.coupon_new_div_saving_inner .drug_name {
  font-weight: bold;
  font-size: 16px;
}

.coupon_new_div_saving_inner .drug_name_subheading {
  font-size: 14px;
  color: #367c9f;
}

.coupon_new_div_saving_inner h5 {
  color: #000;
  font-weight: bold;
  margin-bottom: 25px;
  font-size: 13px;
  padding-top: 10px;
}

.coupon_new_div_expanded {
  padding: 20px 15px;
  margin: 15px 0;
  background-color: rgba(243, 249, 252, 1);
  border-radius: 15px;
}

.coupon_new_div_expanded h3 {
  font-size: 20px;
  color: #000;
  font-weight: bold;
  margin-bottom: 15px;
}

.coupon_new_div_expanded h6 {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  margin-bottom: 5px;
}

.coupon_new_div_expanded .primary_payer_text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  margin-bottom: 5px;
}

.coupon_new_div_expanded h5 {
  font-size: 15px;
  color: #223555;
  font-weight: bold;
  margin-bottom: 5px;
}

.card_info_new {
  text-align: center;
  color: #223555;
  padding: 25px 16px;
  background: linear-gradient(226.49deg, #3783a1 15.16%, #3a64a4 100.77%);
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-radius: 8px 8px 0 0;
}

.car_info_bottom_new {
  background: #fff;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  margin-bottom: 20px;
}

.card_info_new_UHG {
  color: #223555;
  padding: 25px 16px;
  background: #fff;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-radius: 8px 8px 0 0;
}

.car_info_bottom_new_UHG {
  background: linear-gradient(226.49deg, #3783a1 15.16%, #3a64a4 100.77%);
  padding: 12px 16px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  margin-bottom: 20px;
}

.couponTable {
  padding: 12px 16px;
}

.car_info_bottom_new_UHG label,
.couponTable {
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.8px;
  opacity: 0.6;
}

.car_info_bottom_new_UHG h4,
.couponTable {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.3px;
  word-wrap: break-word;
}

.car_info_bottom_new_UHG h4,
.car_info_bottom_new_UHG label {
  color: #fff !important;
}

.couponTable label {
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.7);
  opacity: 0.6;
}

.couponTable h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.7);
}

.card_info_new h2 {
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  margin: 0;
}

.card_info_new h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
}

.card_info_new p {
  text-align: left;
}

.card_info_new span {
  font-size: 11px;
  color: #fff;
  line-height: 15px;
}

.card_info_new_left {
  text-align: left;
}

.card_info_new_right {
  text-align: right;
}

.pharma_instruction {
  font-size: 13px;
}

.medication_search_main_heading {
  color: #fff;
}

.price_comparison_home_4 {
  background-color: inherit;
  padding: 0 8px;
  border-radius: 15px;
  margin: 10px 0 25px 0px;
}

.face_id {
  height: 40px !important;
  margin-bottom: 10px;
}

#btnback {
  background-color: #4adeb9;
  color: #fff;
}

.apple_btn {
  background-color: inherit !important;
  max-width: 270px;
  padding: 0 !important;
}

.white_bg {
  background-color: #fff !important;
  color: #000 !important;
}

.pharma-inner-bg {
  background-color: #f2f8fb !important;
  padding-top: 2px;
}

.turquoise_btn {
  background-color: #4adeb9 !important;
  color: #fff !important;
  border: 0 !important;
}

.ReactModal__Content--after-open {
  height: 200px;
  width: 400px;
  margin: 0 auto;
  top: 25%;
}

.ReactModal__Overlay--after-open {
  background-color: grey;
}

.input-div i {
  position: absolute;
  font-size: 14px;
}

.faSearch {
  padding: 9px 15px;
  color: rgba(34, 34, 34, 1);
}

.search_icon {
  padding: 7px;
}

.search_icon_pharmacy {
  margin-left: -3px;
}

.searchBar-icon {
  margin-top: 15px;
  border-radius: 8px;
  background: rgba(250, 253, 255, 1);
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  padding: 5px 10px;
}

.searchBar-icon li {
  display: flex;
  padding-bottom: 10px;
}

.searchBar-icon a {
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
}

.searchBar-icon h5 {
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.2px !important;
  color: #373f41;
  margin: 0;
  padding: 2px 15px 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-style {
  list-style: none;
  margin: 5px;
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #000;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.search-bar-img {
  position: absolute;
  padding: 4px;
}

.search-bar {
  font-size: 13px !important;
  font-weight: 400;
  border: 0;
  border-radius: 8px !important;
  margin-top: 10px;
  width: 100%;
  padding: 10px 12px 8px 10px;
  background-color: #fafdff !important;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  line-height: 19px;
  padding-left: 39px !important;
  outline: none;
}

.search-bar::placeholder {
  font-size: 13px;
  padding-left: 8px;
}

.pharma-search-bar::placeholder {
  font-size: 12px !important;
}

.small_medication_logo {
  width: 34px;
  height: auto;
  display: inline-block;
  padding: 10px 4px;
}

.text_input_insurance {
  border: 1px solid #ccc;
  margin: 4px 0 10px 0px;
}

.insurance_inputfeild_new {
  border: 1px solid #6094bb;
  padding: 10px 5px;
  margin: 5px 5px;
  border-radius: 15px;
}

.insurance_inputfeild_new button {
  border-radius: 10px !important;
  width: auto;
  margin: 0 4px;
  padding: 3px 16px;
  font-size: 14px;
}

.medication_name {
  color: #000;
  font-size: 21px;
}

.span_link {
  color: #000;
  cursor: pointer;
}

.topSignUp {
  font-weight: 600;
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  text-decoration: dashed;
}

.topSignUp :hover {
  color: #78aba2;
}

.mp_left {
  margin-left: 230px;
  padding-left: 230px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #1c3469;
  border-bottom: 16px solid #859bcd;
  width: 90px;
  margin: 0 auto;
  height: 90px;
  animation: spin 2s linear infinite;
  margin: 0;
  position: absolute;
  top: 47%;
  left: 47%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 11;
}

.faqs-item {
  margin-top: 12px;
}

.overlay_loading {
  position: fixed;
  /* Sit on top of the page content */
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
}

.review-coupon-button {
  margin-top: 10px;
}

.padding3 {
  padding-top: 3px;
}

.insurance-padding {
  padding: 30px !important;
}

.insurance_inner_main {
  padding: 88px !important;
}

.strikeThroughText {
  color: rgba(0, 0, 0, 0.35) !important;
}

.strikeThrough {
  text-decoration: line-through;
  line-height: 16px !important;
}

.editModel select {
  margin: 10px 0;
  background-color: rgba(236, 243, 249, 1) !important;
  height: 42px !important;
  border-radius: 8px;
}

.hrDiv {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
  padding-top: 20px !important;
  margin-top: 10px !important;
  font-weight: 400 !important;
}

.tooltipE {
  background-color: black;
  color: #fff !important;
  position: absolute;
  padding: 5px;
  top: 20%;
  left: 20%;
}

.instructionHeading {
  text-align: center;
}

.phone_popup {
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.7);
}

.phone_popup_error {
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: red;
}

.phone_popup_heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.7);
}

.emailError {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.opacity_1 {
  opacity: 1 !important;
}

.black-text {
  color: #000000 !important;
}

.alignSelfEnd {
  align-self: end;
}

.vector-image img {
  width: 100%;
}

.txtDecoration {
  text-decoration: none;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

input {
  background: #ecf3f9 !important;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.12);
  margin: 5px 0px;
}

.btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  width: 100%;
  margin: 5px 0px;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.15);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
}

.est-retail-price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_success {
  width: 100%;
  padding: 12px 18px 12px 18px;
  border-radius: 8px;
  margin-top: 10px;
  background: #1c3469;
  border: 1px solid #1c3469;
  box-shadow: 0 6px 20px 0 rgba(20, 48, 82, 0.12);
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 19px;
  min-width: fit-content;
  /* height: 41px; */
}

.btn_success,
.save_prescription_btn,
.btn_success:hover {
  background: #344863;
  color: #fff;
  box-shadow: 0 6px 20px 0 rgba(60, 82, 110, 0.1);
}

.btn_default {
  width: 100%;
  padding: 12px 18px 12px 18px;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0 6px 20px 0 rgba(20, 48, 82, 0.12);
  color: #1C3469;
  background: #FAFDFF;
  border: 1px solid #FAFDFF;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 19px;
  /* height: 41px; */
}

.btn_default:hover {
  background: #F3F9FC;
  color: #1C3469;
  box-shadow: 0 6px 20px 0 rgba(243, 249, 252, 0.1);
}

.pointer {
  cursor: pointer;
  text-decoration: none;
}

.modal-backdrop.fade {
  opacity: 0.4 !important;
}

.modal-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.3;
  color: rgba(0, 0, 0, 0.7);
}

.modal-header .btn-close {
  font-size: 16px;

  align-items: center;
  width: 36px;
  height: 36px;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-radius: 50px;
  padding: 0 !important;
}

.medicine_icon {
  display: flex;
  float: right;
  margin: 25px 25px 25px 0px;
  cursor: pointer;
}

.medicine_icon_m {
  margin: 25px 0px 10px 0px !important;
}

.pharma_icon {
  float: left !important;
}

.medicine_icon img {
  /* margin-left: 10px; */
  outline: none !important;
  align-items: center;
  background: #fafdff;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-radius: 50px;
}

.angleUpMain {
  display: flex;
  justify-content: center;
}

.angleUp {
  margin: 0 15px 20px 0;
  cursor: pointer;
  padding: 0px 20px 20px 0;
  position: absolute;
  top: -14px;
}

.medicine_icon i,
.angleUp i,
.crossButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  width: 36px;
  height: 36px;
  background: #fafdff;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-radius: 50px;
  color: rgb(28, 52, 105);
  font-weight: 600;
}

.crossButton {
  border: none !important;
  padding: 0 !important;
  float: right;
}

.findPharmacy_section {
  position: absolute;
  z-index: 999;
  right: 0;
}

.findPharmacy {
  /* background-image: url("../images/map.png"); */
  background-size: cover;
  min-height: 877px;
}

.pharma_inner p {
  margin: 0;
  font-size: 16px !important;
}

.pharma_inner span {
  font-size: 12px !important;
  color: #000;
}

.pharma_inner {
  align-items: flex-start;
  padding: 10px 16px;
  gap: 16px;
  background: #fafdff;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-radius: 8px;
  margin: 25px 25px 0px 25px;
}

.input_pharma input,
.input_pharma i {
  background-color: #ecf3f9 !important;
  color: rgba(0, 0, 0, 0.35) !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.autoMargin {
  margin: auto !important;
}

.numberVerify {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  height: 38px;
  margin-top: 10px;
}

.numberVerify input {
  margin: 10px 5px;
  background: #ecf3f9;
}

.page_counter {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 5px;
}

.page_counter h3,
.page_counter h4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 38px;
  height: 38px;
  background: #d1d6d8;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-radius: 60px;
  margin-right: 20px;
}

.page_counter p:hover {
  background: #1c3469;
  opacity: 0.8;
}

.page_counter .active {
  background: #1c3469;
  width: 43px;
  height: 43px;
}

.dot {
  cursor: pointer;
  height: 6px;
  width: 6px;
  margin: 0 5px;
  background-color: #8b99b4;
  border-radius: 50%;
  display: inline-block;
}

.dotActive {
  background-color: #1c3469 !important;
  height: 8px;
  width: 8px;
  display: inline-block;
}

.bottom {
  bottom: 10px;
  padding: 0px;
  text-align: center;
}

/* Fading animation */
.fadeAni {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.visibilityNon {
  visibility: none;
}

.wallet-img {
  margin: 10px 0px;
  text-align: center;
}

.wallet-img img {
  max-width: 100%;
  height: 50px;
}

.coupon_link a {
  color: #1c3469 !important;
  text-decoration: dashed;
}

.coupon-inner {
  margin: 10px 0px 0px 0px;
  padding: 16px 16px 5px 16px;
  gap: 16px;
  background-color: #387aa2 !important;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: left;
  border: 0;
}

.coupon-inner p,
.coupon-inner span,
.coupon-inner h3 {
  color: #fff !important;
}

.coupon-inner-table {
  padding: 0px 16px;
  gap: 16px;
  background: #fafdff;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: left;
  border: 0;
}

.table tr {
  border: #fafdff;
  font-size: 12px;
  font-weight: 600;
}

th {
  color: #919394;
}

td {
  color: #4b4c4d;
}

hr {
  color: rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 15px 0px !important;
}

.fa-trash-alt:hover {
  color: red;
}

.med_angle-right {
  padding: 7px;
}

.alternatives {
  border: 1px solid #1c3469;
  border-radius: 10px;
  padding: 5px;
}

.factorial {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 14px;
  width: 30px;
  height: 32px;
  background: #1c3469;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
  border-radius: 50px;
  color: #fff;
  margin: 15px 0px 0px 10px;
}

.pharmacyINC {
  cursor: pointer;
}

.img-pill {
  padding-top: 10px;
}

.font42 sup,
.sup {
  font-size: 11px !important;
  margin-right: 3px;
}

.weight600 {
  font-weight: 600 !important;
}

.font13 {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 19px;
  letter-spacing: 0.2px !important;
}

.shopName {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.couponTable th {
  font-size: 11px;
  text-align: left;
  font-weight: 600;
  padding: 15px 25px 5px 25px;
}

.couponTable td {
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  padding: 0px 25px;
}

.tabCoupon .btn {
  padding: 7px 15px !important;
}

.hrDivpadding {
  padding-top: 14px !important;
}

@media screen and (max-width: 1400px) {
  .no-record {
    width: 36.5%;
  }
}

@media screen and (min-width: 1300px) {

  .insurance_inner h1,
  .medications_inner_coupon h1 {
    font-size: 35px;
  }
}

@media screen and (max-width: 1300px) {
  .no-record {
    width: 39.5%;
  }
}

@media screen and (max-width: 1250px) {
  .no-record {
    width: 41%;
  }
}

@media screen and (max-width: 1200px) {
  .no-record {
    width: 42.5%;
  }

  .container {
    max-width: 95%;
  }

  .insurance-padding {
    padding: 30px !important;
  }

  .insurance_inner_main {
    padding: 72px;
  }
}

@media screen and (max-width: 1163px) {
  .no-record {
    width: 44.5%;
  }

  .med_padding {
    padding: 25px 0;
  }
}

@media screen and (min-width: 1150px) {
  .med_padding {
    padding: 25px 40px;
  }
}

@media screen and (min-width: 1101px) {
  .imhere_main {
    width: 50%;
    margin: 64px auto 0 auto;
  }

  .inst-ff {
    padding: 64px;
  }

  .med_padding {
    padding: 25px 20px;
  }

  .insurance_inner h1,
  .medications_inner_coupon h1 {
    font-size: 27px;
    line-height: 37px;
  }
}

@media screen and (max-width: 1100px) {
  .no-record {
    width: 46.5%;
  }

  .insurance_inner h1,
  .medications_inner_coupon h1 {
    font-size: 20px;
    line-height: 37px;
  }

  .imhere_main {
    width: 50%;
    margin: 64px auto 0 auto;
  }

  .med_padding {
    padding: 25px 10px;
  }
}

@media screen and (max-width: 1080px) {
  .no-record {
    width: 47.5%;
  }

  .med_padding {
    padding: 25px 10px;
  }

  .margintop10 {
    margin-top: 0%;
  }

  .insurance-padding {
    padding: 30px !important;
  }

  .insurance_inner_main {
    padding: 77px 40px !important;
  }
}

@media screen and (max-width: 1250px) {}

@media screen and (min-width: 992px) {
  .insurance_inner_main_pad .pharma_inner {
    margin: 25px 25px 0px 25px !important;
  }

  .dlr-Sup sup {
    font-size: 11px;
  }

  body {
    background-image: url(../images/web.png);
  }

  .inst-ff {
    padding: 50px;
  }

  .tabletView {
    display: none;
  }

  .display_None {
    display: visible !important;
  }

  .insurance_inner_main {
    padding: 88px;
    width: 90%;
    background: #fafdff;
    box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
    border-radius: 8px;
    text-align: left;
    border: 0;
  }

  .insurance_inner {
    box-shadow: none !important;
    padding: 10px 0px !important;
  }

  .insurance_inner_main_btn {
    display: none;
  }

  .margintop10 {
    margin-top: 9%;
  }

  .numberVerify input {
    width: 38px;
    height: 38px;
  }

  .tabView {
    display: none;
  }

  .div-hidden {
    display: visible !important;
  }

  .couponTabFix {
    display: block !important;
  }

  .seeCoupon {
    display: none !important;
  }

  .mobileView-tabView {
    visibility: hidden;
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .no-record {
    width: 70%;
  }

  body {
    background-image: url(../images/tab.png) !important;
  }

  .inst-ff {
    padding: 25px;
  }

  .pharmacyImg_div {
    min-height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 5px;
  }

  .imhere_main {
    width: 100%;
    margin: 64px auto 0 auto;
  }

  .showDivonWeb {
    display: none;
  }

  .tabletView {
    display: visible;
  }

  .tabMargin {
    margin-bottom: 50%;
  }

  .couponMobFixed {
    position: fixed;
    bottom: 0%;
    left: 0px;
    width: 88%;
    margin: 0px auto;
    right: 0;
    padding: 0px;
    z-index: 999;
  }

  .display_None {
    visibility: hidden;
    display: none;
  }

  .tab-fluid {
    padding-left: 0;
  }

  .tab-fluid img {
    max-width: 70px !important;
  }

  .tabView {
    display: visible;
  }

  .seeCoupon {
    display: visible !important;
  }

  .medications_inner_coupon h2 {
    font-size: 27px;
    font-weight: 600;
    line-height: 37px;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.7);
    margin: 0;
  }

  .div-hidden {
    display: none !important;
  }

  .insurance-padding {
    padding: 30px !important;
  }

  .insurance_inner_main {
    padding: 50px;
  }

  .insurance_inner {
    padding: 50px;
  }

  .insurance_inner_main_btn2 {
    display: none;
  }

  .margintop10 {
    margin-top: 0;
  }

  .mobileView-tabView {
    visibility: visible;
  }

  .pharma_home_one {
    margin: 0px;
  }

  .insurance_inner_main_pad {
    padding: 0 !important;
  }
}

@media screen and (max-width: 840px) {
  .no-record {
    width: 68%;
  }

  .insurance_inner h1,
  .medications_inner_coupon h1 {
    font-size: 27px;
    line-height: 37px;
  }
}

@media screen and (min-width: 800px) {
  .amountFountSize {
    padding-left: 12px !important;
  }
}

@media screen and (min-width: 768px) {
  .amountFountSize {
    padding-left: 0;
  }

  .amountFountSize h4,
  .amountFountSize label {
    /* font-size: 13px; */
  }

  .modal-custom-className {
    max-width: 90% !important;
  }

  /*
  .box-action {
    display: none;
  } */

  .imhere_main {
    min-width: 680px;
  }

  .modal-custom-class {
    max-width: 88% !important;
  }

  .mobileView {
    visibility: hidden;
    display: none !important;
  }

  .webView {
    visibility: visible;
  }

  .couponTabFix {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: end;
    height: 31vh;
  }

  .home_one {
    margin: 25px 25px 0px 0px !important;
  }

  .paddingZero {
    padding: 0px !important;
  }

  .pharma-inner-bg {
    background-color: #fff !important;
  }

  .medications_inner_right {
    text-align: center;
  }

  /* .back_icon {
    margin: 25px 0px !important;
  } */
}

@media screen and (max-width: 767px) {
  .pharmacyImg_div {
    min-height: 75px !important;
  }

  .swipe-list {
    position: relative;
  }

  .box-action i {
    /* position: absolute; */
    /* right: 4%; */
    /* top: 40%; */
    background-color: rgb(28, 52, 105, 1);
    color: #fff;
    border-radius: 30px;
    padding: 15px;
  }

  .vertical-ellipse img {
    /* transform: rotate(90deg); */
    margin-left: 10px;
    align-items: center;
    background: #fafdff;
    box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
    border-radius: 50px;
    padding: 5px;
    cursor: pointer;
    float: right;
  }

  .item-group {
    overflow: hidden;
  }

  .item {
    position: relative;
    z-index: 2;
    transition: all 0.1s linear;
    transform: translate(-50px);
  }

  .modal-custom-class {
    max-width: 100%;
    margin: 0 !important;
  }

  .imhere_main {
    width: 95%;
    margin: 16px auto 0 auto;
  }

  .inst-ff {
    padding: 16px;
  }

  .med_padding {
    padding: 16px;
  }

  .tabletView {
    display: none;
  }

  .couponMobFixed {
    width: 100%;
  }

  .medications_inner h4 {
    font-size: 16px;
  }

  .card_info__ {
    font-size: 20px;
  }

  .card_info_new h4 {
    font-size: 16px;
  }

  .insurance-padding {
    padding: 30px !important;
  }

  .insurance_inner_main {
    padding: 16px !important;
  }

  .login_section h3 {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 10px 0 !important;
  }

  .margin-right {
    margin: 0;
  }

  .insurance_inner_main_btn {
    display: none !important;
  }

  .insurance_inner_main_btn2 {
    display: block;
  }

  .insurance_inner h1 {
    font-size: 27px;
    line-height: 37px;
  }

  .insurance-padding {
    padding: 30px !important;
  }

  .insurance_inner_main {
    padding: 40px;
  }

  .insurance_inner {
    padding: 20px 60px;
  }

  .login_section p {
    font-size: 16px;
  }

  .login_section_inner {
    padding: 30px;
    margin: 0 16px;
  }

  .webView {
    visibility: hidden;
    display: none !important;
  }

  .mobileView {
    visibility: visible;
  }

  .tabView {
    display: none !important;
  }

  .loader {
    top: 45%;
    left: 40%;
  }

  .coupon_main .medications_inner {
    background-color: #fafdff;
    margin: 0px;
    box-shadow: 0px 0px 0px 0px;
    padding-bottom: 0px;
  }

  .imhere_main .medications_inner {
    background-color: #fafdff;
    margin: 0px;
    box-shadow: 0px 0px 0px 0px;
    padding-bottom: 0px;
  }

  .coupon .container {
    max-width: 100% !important;
    padding: 0px !important;
  }

  /* .back_icon {
    margin: 25px 12px 10px 12px !important;
  } */
  /* .back_icon {
    margin: 25px 0px !important;
  } */

  .containerCustom {
    max-width: 100%;
  }
}

@media screen and (max-width: 639px) {
  .img-size {
    display: none;
  }

  .price_comparison_home_4 button {
    padding: 8px 12px;
  }
}

@media screen and (max-width: 575px) {
  .padding_left_0 {
    padding-left: 0 !important;
  }

  .padding_right_0 {
    padding-right: 0 !important;
  }

  .medications_inner h5,
  .card_info_new h5 {
    font-size: 10px !important;
  }

  .vertical-ellipse img {
    float: inherit !important;
    margin-left: 0;
  }
}

@media screen and (min-width: 501px) {
  .insurance_inner_main_pad .pharma_inner {
    margin: 25px 100px 0px 100px;
  }

  .medications_inner h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .insurance_inner_main_pad .pharma-inner-bg {
    margin: -5px !important;
  }

  .price_comparison_home_4 p {
    font-size: 16px !important;
  }

  .modal-custom-className {
    max-width: 100% !important;
    margin: 0 !important;
    align-items: end !important;
  }

  .font_14_force {
    font-size: 14px !important;
  }

  .modal-content {
    padding: 0 15px;
  }

  body {
    background-image: url(../images/mob.png) !important;
  }

  .modal-content {
    padding: 10px;
  }

  .modal-content {
    padding: 0px;
  }

  .container {
    padding: 5px !important;
  }

  .instructionHeading {
    text-align: left !important;
    padding-left: 10px;
  }

  .review-coupon {
    padding: 0px 5px;
  }

  .imhere_main {
    width: 50%;
    margin: 64px auto 0 auto;
  }

  .inst-ff {
    padding: 64px;
  }

  .insrance_card_bottom_label {
    font-size: 11px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    color: rgba(255, 255, 255, 1) !important;
    opacity: 1 !important;
  }

  .insrance_card_bottom_label_black {
    font-size: 11px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    color: rgba(0, 0, 0, 0.7) !important;
  }

  .card_info_new_UHG .mobileView h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.7);
  }

  .card_info_new_UHG .mobileView h5 {
    font-size: 11px !important;
    font-weight: 400 !important;
    line-height: 15px !important;
    color: rgba(0, 0, 0, 0.7);
  }

  .pharmac_inst_h4_mobile {
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: rgba(0, 0, 0, 0.7);
  }

  .imhere_main {
    width: 100%;
    margin: 16px auto 0 auto;
  }

  .inst-ff {
    padding: 16px;
  }

  .couponMobFixed {
    bottom: 0;
  }

  .card_info_new h2 {
    font-size: 11px !important;
  }

  .table tr {
    font-size: 11px !important;
  }

  .couponTable th {
    font-size: 11px !important;
    padding: 10px 15px !important;
  }

  .couponTable td {
    font-size: 11px !important;
    padding: 5px 15px !important;
  }

  .medications_inner p {
    font-size: 11px;
  }

  .popupCouponCard .container {
    max-width: 100% !important;
    padding: 0 !important;
  }

  .container {
    max-width: 95% !important;
  }

  .medications_inner_coupon {
    margin-bottom: 0;
    margin: 0 0 !important;
  }

  .button_div {
    display: contents;
  }

  .NIbutton {
    width: auto !important;
  }

  .margin-right {
    margin-right: 0px;
  }

  .insurance-padding {
    padding: 30px !important;
  }

  .insurance_inner_main {
    padding: 20px;
  }

  .insurance_inner {
    padding: 20px;
  }

  .medications_inner h3 {
    font-size: 14px;
  }

  .medications_inner {
    margin: 9px 0px 0px 0;
    box-shadow: 0px 6px 7px rgba(20, 48, 82, 0.12);
  }
}

@media screen and (max-width: 410px) {
  .btn {
    padding: 12px 10px !important;
  }

  .medications_inner h3 {
    font-size: 12px;
  }

  .medications_inner_right {
    text-align: center;
  }

  .vertical-ellipse img {
    float: inherit !important;
  }
}

@media screen and (max-width: 389px) {
  .drugs_inner_best_deals h4 {
    font-size: 17px;
  }

  .sup {
    font-size: 9px !important;
  }

  .medications_inner h3 {
    font-size: 10px;
  }
}

@media screen and (max-width: 350px) {
  .medication_name {
    font-size: 19px;
  }
}

@media screen and (max-width: 327px) {
  .drugs_inner_best_deals h4 {
    font-size: 15px;
  }
}

@media screen and (max-width: 320px) {
  .coupon_new_div_saving_inner button {
    padding: 2px 0px;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .apple_btn img {
    height: auto;
  }

  .close_save_responsive {
    padding: 3px 16px !important;
  }
}

.coupon_popup {
  background-image: url(../images/web.png);
  background-size: cover;
  border-radius: 15px;
  width: 900px;
  margin-left: -200px;
}

.coupon_popup .inner {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
}

.coupon_popup .c_row {
  margin: 20px 40px 40px 0px;
}

.coupon_popup .x {
  justify-content: flex-end;
  margin: 10px 37px 0px 0px;
}

.coupon_popup_tab {
  background-image: url(../images/web.png);
  background-size: cover;
  border-radius: 15px;
  padding: 20px !important;
}

.coupon_popup_tab .inner {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.12);
}

.coupon_popup_tab .x {
  justify-content: flex-end;
  margin: 0px 0px 0px 0px;
}

.coupon_popup_tab .c_row {
  margin: 20px 40px 40px 0px;
}

.paper-plane-image img {
  width: 80%;
}

/* CPD */
.float-left {
  float: left;
}

.CPDImgVal {
  display: flex;
  position: relative;
  top: 2px;
  margin: auto;
  background-color: #4bd10e;
  border-radius: 20px;
  padding: 0px 10px;
  width: 70%
}

.CPDImgVal sup,
.CPDImgVal h6,
.CPDImgVal h3 {
  color: #fff !important;
  font-size: 12px !important;
}

.margin-5 {
  margin-top: -5px;
}

.CPDIMG {
  width: 90px;
}

.marginAuto {
  margin: auto !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid rgba(221, 91, 117, 1) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.Toastify__toast {
  width: 100%;
}

.medication-search-margin {
  margin-bottom: 24px !important;
  background-image: url('../../assets/images/RxLink assets/new assets/Search_light.svg') !important;
  background-position: 7px 7px !important;
  background-repeat: no-repeat !important;
}

.primary_button {
  background: #1c3469;
  border: 1px solid #1c3469;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 19px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  width: 100%;
  margin: 5px 0px;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.15);
  border-radius: 8px;
}

.primary_button:hover {
  background: #3c526e;
}

.secondary_button {
  background: #1c3469;
  border: 1px solid #1c3469;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 19px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  width: 100%;
  margin: 5px 0px;
  box-shadow: 0px 6px 20px rgba(20, 48, 82, 0.15);
  border-radius: 8px;
}

.secondary_button:hover {
  background: #3c526e;
}


input[type="checkbox"] {
  accent-color: #1C3469;
}

input:focus {
  box-shadow: 0 0 0 1px rgba(28, 52, 105, 1) !important;
}

select:focus {
  box-shadow: 0 0 0 1px rgba(28, 52, 105, 1) !important;
}

select {
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.12);
}

.Toastify__toast {
  border-radius: 8px !important;
}

.Toastify__toast-container--bottom-center {
  width: 96% !important;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--bottom-center {
    width: 96% !important;
    transform: translateX(2%) !important;
  }
}

.warn-feedback {
  /* display: none; */
  width: 100%;
  font-size: .675em;
  color: gray;
}

.toast_dismiss {
  color: #1C3469;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  margin-top: 15px;
}

.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: none;
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #ffffff !important;
}

.ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #ffffff !important;

}

.ant-tabs-nav-wrap {
  font-size: 13px;
  font-family: "Montserrat";
  font-weight: 600;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000E5 !important;
  font-size: 13px;
}

.ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #000000A6 !important;
  font-size: 13px;
}

.ant-tabs-tab-btn {
  color: #0000008F !important;
  font-size: 13px;
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid #1C3469;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #F3F9FC;
}

.table-container {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
}

.table-container-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.ant-table-wrapper {
  min-width: 1010px;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  padding: 8px;
  background-color: #ffffff !important;
  font-size: 11px;
  font-weight: 600;
  color: #0000008F;
  border-bottom: transparent;
}

.ant-table-wrapper .ant-table-thead>tr>td {
  padding: 8px;
  background-color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  color: #0000008F;
  border-bottom: transparent;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 6px;
  font-size: 13px;
  font-weight: 400;
  color: #373F41;
  border-bottom: transparent;
}

.ant-table-wrapper .ant-table-column-sorters {
  font-size: 11px;
  font-weight: 600;
  color: #0000008F;
  justify-content: flex-start !important;
}

.ant-table-wrapper .ant-table-column-sorter-down {
  font-size: 8px;
}

.ant-table-wrapper .ant-table-column-sorter-up {
  font-size: 8px;
}

.ant-table-wrapper .ant-table-column-title {
  flex: 0 1 auto;
}

.ant-table-wrapper td.ant-table-column-sort {
  background-color: transparent;
}

.hover-span {
  cursor: pointer;
  border-radius: 5px;
  padding: 0px 5px;
  background-color: #e8f7f7;
  margin-bottom: 4px;
}

.hover-span-disable {
  cursor: pointer;
  border-radius: 5px;
  padding: 0px 5px;
  background-color: #d9d9d9;
  margin-bottom: 4px;
}

.hover-span:hover {
  background-color: #F3F9FC;
}
.hover-span-disable:hover {
  background-color: #d9d9d9;
}
.ant-table-wrapper .ant-table-cell-fix-right {
  box-shadow: 0px 6px 10px 0px #1430521F;
}

.ant-modal .ant-modal-close:hover {
  background-color: #ffffff;
}

.ant-modal-content {
  background-color: #FAFDFF !important;
  font-family: "Montserrat" !important;
  padding: 16px !important;
}

.antd-modal-width {
  min-width: 343px;
  max-width: 645px;

}

/* .message-modal{
  overflow-y: hidden !important;
  -ms-overflow-style: none !important;
}
.antd-modal .modal-content{
  overflow-y: scroll;
  scrollbar-width: thin;
} */
.antd-modal-width-calendar {
  min-width: 343px;
  max-width: 448px;

}

.antd-modal-title {
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 400;
}

.ant-modal .ant-modal-header {
  background-color: #FAFDFF !important
}

.ant-modal-footer-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px
}

@media screen and (min-width: 450px) {
  .ant-modal-footer-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.antd-modal-hidden {
  display: none !important;
}

.input-wrapper-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
  height: 15px;
  cursor: pointer;
}

.ant-picker-body {
  background-color: #FAFDFF !important;
}

.ant-picker-calendar .ant-picker-panel {
  border-top: none;
}

.ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
  display: none;
}

.ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) {
  pointer-events: none;
}

.ant-picker-calendar .ant-picker-cell .ant-picker-cell-inner {
  width: 36px;
  height: 36px;
  padding: 6px;
  border-radius: 99999px;
  font-family: "Montserrat" !important;
  font-size: 13px;
  font-weight: 600;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background: #1C3469 !important;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none;
}

.ant-picker-calendar-date-today {
  background: #ECF3F9;
}

.ant-picker-calendar .ant-picker-date-panel .ant-picker-content th {
  font-family: "Montserrat" !important;
  font-size: 11px;
  font-weight: 600;
  color: #606060;
  text-transform: uppercase;
}

.ant-picker-calendar .ant-picker-cell-disabled::before {
  background: none;
}

.my-calendar table thead tr>th:nth-child(1)::after {
  content: "n";
}

.my-calendar table thead tr>th:nth-child(2)::after {
  content: "n";
}

.my-calendar table thead tr>th:nth-child(3)::after {
  content: "e";
}

.my-calendar table thead tr>th:nth-child(4)::after {
  content: "d";
}

.my-calendar table thead tr>th:nth-child(5)::after {
  content: "u";
}

.my-calendar table thead tr>th:nth-child(6)::after {
  content: "i";
}

.my-calendar table thead tr>th:nth-child(7)::after {
  content: "t";
}

.rotate-side-icon {
  transform: rotate(180deg);
}


.patient-pharmacy-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  .inner-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 5px; */
  }
}

@media screen and (min-width: 600px) {
  .patient-pharmacy-info {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}

.current-dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #1C3469;
}

.previous-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #6E6F70;
}

.next-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FAFDFF;
  border: 2px solid #1C3469
}

.status-flow-line {
  width: 2px;
  height: 20px;
  background-color: #1C3469;
  margin-left: 4.5px;
  margin-top: 28px;
  position: absolute;
}

.tooltip-div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.ant-tooltip .ant-tooltip-inner {
  color: #6E6F70;
  font-size: 11px;
  background: #FAFDFF;
}

.ant-tooltip {
  --antd-arrow-background-color: #FAFDFF;
}

.ant-modal .ant-modal-footer {
  margin-top: 0px;
}

.modal-content {
  max-height: 50svh;
  overflow-y: scroll;
  margin: 20px 0px 0px 0px;
  scrollbar-width: thin;

  /* -ms-overflow-style: ; */
}

.hide-scroll {
  overflow-y: auto;

}

.hide-scrollbar .modal-content {
  overflow-y: auto;
}

.rest-password-modal .modal-content {
  max-height: 550svh;
  overflow-y: hidden;
  margin: 12px 0px 0px 0px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* .modal-content::-webkit-scrollbar {
  display: none;
} */

.center-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #1c3469;
  border-bottom: 4px solid #859bcd;
  width: 19px;
  height: 19px;
  animation: spin 2s linear infinite;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 11;
}

.no-data-message-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60svh;
}

.no-data-message {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  color: #0000008F;
}

.ant-select-selector input {
  background-color: #ffffff !important;
  box-shadow: none !important;
}